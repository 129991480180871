html {
    height: 100%;
}
body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    height: 100%;
}
a {
    color: #2465c3;
}

#root {
    height: 100%;
    /* overflow-x: hidden; */
}
.mb-15 {
    margin-bottom: 15px;
}
.mt-5 {
    margin-top: 5px;
}
.mt-15{
    margin-top: 15px;
}
.mt-30{
    margin-top: 30px;
}
.mt-45 {
    margin-top: 45px;
}
.mb-30 {
    margin-bottom: 30px;
}
.pt-50 {
    padding-top: 50px;
}
.mln-15 {
    margin-left: -15px;
}
.pr-0 {
    padding-right: 0px;
}
.pd-0 {
    padding: 0px;
}
.pd-15 {
    padding: 15px;
}
.height-100{
    height: 100%;
}
.sticky {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
}

.primary-cta {
    background-color: #f5b604;
    padding: 10px 20px;
    border: none;
    color: #fff;
}
.primary-cta:hover {
    background-color: #f0a604;
}

.secondary-btn {
    padding: 10px 20px;
    background-color: #fff;
    color: #333;
}
.text-right-dk {
    text-align: left;
}

/*Override Bootstrap */
.form-control:disabled, .form-control[readonly] {
    background-color: #fff;
}

/*Over ride Cognito */

.amplify-form-row label {
    padding-left: 5px !important;
}

.amplify-section-body .amplify-action-row {
    margin-top: 10px !important;
}
@media(max-width: 767px){
    .amplify-form-section {
        max-width: 300px;
    }
}
/*Over ride Cognito */
@media(min-width: 767px){
    .text-right-dk {
        text-align: right;
    }
}